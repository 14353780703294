import React from "react";

import styles from "./categories.module.css";

function Categories({ handleCategory, nodes, selectedCategory }) {
  console.log(selectedCategory);
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.category}>
          <ul className={styles.grid}>
            {nodes &&
              nodes.map((node, i) => (
                <li key={node.id}>
                  <div className={styles.categoryItem} onClick={handleCategory}>
                    <span className={selectedCategory === node.title ? styles.active : ""}>
                      {node.title}
                    </span>
                    <span
                      style={{ display: i === nodes.length - 1 ? "none" : "inline-block" }}
                      className={styles.itemDivider}
                    >
                      &#8725;
                    </span>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Categories;
